import {bindable} from 'aurelia-framework';
import {CanvasHelper} from "../classes/CanvasHelper";
import {MathHelper} from "../classes/MathHelper";
import {RuntimeInfo} from "../classes/RuntimeInfo";

const moment = require("moment");

export class Chart {
    @bindable options;

    static readonly halfWidth = 0.5;
    static readonly topOffset = 10;
    static readonly rightOffset = 30;
    static readonly bottomOffset = 42;
    static readonly axisOffset = 70;

    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;

    xAxis: IAxis;
    yAxis: IAxis[];

    leftOffset: number;
    chartWidth: number;
    chartHeight: number;
    chartRight: number;
    chartBottom: number;

    ySeparations: number;
    xSeparations: number;

    onMouseMove: (event: any) => void;
    onClick: (event: any) => void;
    onResize: () => void;

    bind() {
    }

    attached() {
        const that = this;

        this.ctx = this.canvas.getContext('2d');

        this.onResize = () => {
            that.buildChart(that.options);
        };

        this.onClick = (event) => {
            const rect = that.canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            let axisClicked = false;

            for (let i = 0; i < that.yAxis.length; i++) {
                if (x >= Chart.axisOffset * i && x <= Chart.axisOffset * i + Chart.axisOffset && y > Chart.topOffset && y < that.canvas.height - Chart.bottomOffset) {
                    that.options.yAxis[i].disabled = !that.options.yAxis[i].disabled;
                    axisClicked = true;
                }
            }

            if (axisClicked) {
                that.buildChart(that.options);
            }
        };

        this.onMouseMove = (event) => {
            const rect = that.canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            let hoveredAxis = false;
            let hoveredData = [];

            for (let i = 0; i < that.yAxis.length; i++) {
                if (x >= Chart.axisOffset * i && x <= Chart.axisOffset * i + Chart.axisOffset && y > Chart.topOffset && y < that.chartBottom) {
                    hoveredAxis = true;
                }
            }

            for (let j = 0; j < that.yAxis.length; j++) {
                for (let i = 0; i < that.yAxis[j].data.length; i++) {
                    const dataX = that.leftOffset + (this.chartWidth * MathHelper.percentDateRange(this.xAxis.rangeFrom, this.xAxis.rangeTo, that.yAxis[j].data[i].x));

                    if (x >= dataX - 10 && x <= dataX + 10 && y > Chart.topOffset && y < that.chartBottom) {
                        hoveredData.push([j, i]);
                    }
                }
            }

            if (hoveredAxis) {
                that.canvas.style.cursor = 'pointer';
            } else {
                that.canvas.style.cursor = 'default';
            }

            if (hoveredData.length > 0) {
                that.buildChart(that.options);
                that.showTooltip(x, y, hoveredData);
            } else {
                that.buildChart(that.options);
            }
        };

        this.canvas.addEventListener('click', this.onClick);
        this.canvas.addEventListener('mousemove', this.onMouseMove);

        window.addEventListener('resize', this.onResize);
    }

    showTooltip(x, y, hoveredData) {
        const texts = [];
        let averageX = 0;
        let maxTextLength = 0;
        let totalLines = 0;

        this.ctx.font = '14px Verdana';

        for (let i = 0; i < hoveredData.length; i++) {
            const axis = this.yAxis[hoveredData[i][0]];
            const data = axis.data[hoveredData[i][1]];

            averageX += MathHelper.percentDateRange(this.xAxis.rangeFrom, this.xAxis.rangeTo, data.x);

            let textDateGroup = texts.find((t) => t.date === moment(data.x).format('DD.MM.YYYY HH:mm'));

            if (!textDateGroup) {
                textDateGroup = {
                    date: moment(data.x).format('DD.MM.YYYY HH:mm'),
                    texts: [],
                    colors: []
                }

                texts.push(textDateGroup);

                totalLines++;
            }

            if (Array.isArray(data.y)) {
                textDateGroup.texts.push(`${axis.name}: ${data.y.join('/')} ${axis.unit}`);
            } else {
                textDateGroup.texts.push(`${axis.name}: ${data.y} ${axis.unit}`);
            }

            textDateGroup.colors.push(axis.color);

            totalLines++;

            maxTextLength = Math.max(maxTextLength, this.ctx.measureText(textDateGroup.texts[textDateGroup.texts.length - 1]).width);
        }

        texts.sort((a, b) => {
            if (moment(a.date).isAfter(moment(b.data))) {
                return -1
            } else {
                return 1;
            }
        });

        averageX /= hoveredData.length;

        const dataX = this.leftOffset + (this.chartWidth * averageX);
        const commands = [];
        const lineHeight = 20;
        const width = maxTextLength + 10;
        const height = totalLines * lineHeight + 3;
        let offsetX = dataX + 10;
        let offsetY = y - height / 2;
        let horizontalFlip = false;

        if (offsetX + width > this.canvas.width) {
            offsetX = dataX - width - 30;
            horizontalFlip = true;
        }

        if (offsetY + height > this.canvas.height) {
            offsetY -= (offsetY + height) - this.canvas.height;
        }

        if (offsetY < 0) {
            offsetY = 0;
        }

        commands.push({
            type: 'roundrect',
            x: offsetX,
            y: offsetY,
            width: width + 20,
            height: height,
            color: 'rgba(0,0,0, 0.8)',
            stroke: false
        });

        if (horizontalFlip) {
            commands.push({
                type: 'line',
                path: [
                    {x: offsetX + width + 25, y: offsetY + height / 2},
                    {x: offsetX + width + 19 + Chart.halfWidth, y: offsetY + height / 2 - 5},
                    {x: offsetX + width + 19 + Chart.halfWidth, y: offsetY + height / 2 + 5}
                ],
                fill: true,
                stroke: false
            });
        } else {
            commands.push({
                type: 'line',
                path: [
                    {x: offsetX - 5, y: offsetY + height / 2},
                    {x: offsetX + Chart.halfWidth, y: offsetY + height / 2 - 5},
                    {x: offsetX + Chart.halfWidth, y: offsetY + height / 2 + 5}
                ],
                fill: true,
                stroke: false
            });
        }

        let yHeight = offsetY;

        for (let j = 0; j < texts.length; j++) {
            const text = texts[j];

            commands.push({
                type: 'text',
                text: text.date,
                x: offsetX + 5,
                y: yHeight + lineHeight - 5,
                font: 'bold 14px Verdana',
                color: '#FFFFFF',
            });

            yHeight += lineHeight;

            for (let i = 0; i < text.texts.length; i++) {
                commands.push({
                    type: 'rect',
                    x: offsetX + 5,
                    y: yHeight + 3,
                    width: 16,
                    height: 16,
                    color: text.colors[i]
                });

                commands.push({
                    type: 'text',
                    text: text.texts[i],
                    x: offsetX + 25,
                    y: yHeight + lineHeight - 5,
                    color: '#FFFFFF'
                });

                yHeight += lineHeight;
            }
        }

        CanvasHelper.draw(this.ctx, {}, commands);
    }

    detached() {
        window.removeEventListener('resize', this.onResize);
        this.canvas.removeEventListener('click', this.onClick);
        this.canvas.removeEventListener('mousemove', this.onClick);
    }

    optionsChanged(options) {
        this.buildChart(options);
    }

    buildChart(options) {
        this.canvas.style.width = options.width;
        this.canvas.style.height = options.height;

        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;

        this.xAxis = {
            scale: options.xAxis.scale,
            rangeFrom: options.xAxis.rangeFrom,
            rangeTo: options.xAxis.rangeTo
        };

        this.yAxis = this.buildYAxis(options);

        const timeSize = (moment(this.xAxis.rangeTo).valueOf() - moment(this.xAxis.rangeFrom).valueOf()) / 1000 / 60;

        this.xSeparations = timeSize / this.xAxis.scale;
        this.ySeparations = 10;

        this.leftOffset = Chart.axisOffset * this.yAxis.length;
        this.chartWidth = this.canvas.width - this.leftOffset - Chart.rightOffset;
        this.chartHeight = this.canvas.height - Chart.topOffset - Chart.bottomOffset;
        this.chartRight = this.canvas.width - Chart.rightOffset;
        this.chartBottom = this.canvas.height - Chart.bottomOffset;

        this.drawCanvas();
    }

    buildYAxis(options) {
        const axises = options.yAxis.map((axis) => {
            const scales = [0.5, 1, 2, 5, 10, 20, 50];
            const halfSeparators = 5;
            let rangeFrom = axis.rangeFrom;
            let rangeTo = axis.rangeTo;
            let min = Number.MAX_VALUE;
            let max = Number.MIN_VALUE;

            axis.data.forEach((item) => {
                if (typeof item.y === 'number') {
                    min = Math.min(min, item.y);
                    max = Math.max(max, item.y);
                } else {
                    item.y.forEach((val) => {
                        if (isNaN(val)) return;

                        min = Math.min(min, val);
                        max = Math.max(max, val);
                    });
                }
            });

            if (!axis.rangeStatic) {
                if (axis.data.length > 0) {
                    axis.rangeFrom = Math.round(min);
                    axis.rangeTo = Math.round(max);

                    do {
                        axis.rangeFrom--;
                    } while (axis.rangeFrom % axis.scale !== 0);

                    do {
                        axis.rangeTo++;
                    } while (axis.rangeTo % axis.scale !== 0);
                }

                let middle = Math.round((axis.rangeTo - axis.rangeFrom) / 2 + axis.rangeFrom);
                let usedScale = 0;

                while (middle - halfSeparators * scales[usedScale] > axis.rangeFrom || middle + halfSeparators * scales[usedScale] < axis.rangeTo) {
                    usedScale++;
                }

                while (middle % scales[usedScale] !== 0) {
                    middle++;
                }

                axis.rangeFrom = middle - halfSeparators * scales[usedScale];
                axis.rangeTo = middle + halfSeparators * scales[usedScale];

                if (axis.rangeFrom < 0) {
                    axis.rangeTo += axis.rangeFrom;
                    axis.rangeFrom = 0;
                }
            }

            return {
                name: axis.name,
                unit: axis.unit,
                type: axis.type,
                color: axis.color,
                scale: axis.scale,
                rangeFrom: axis.rangeFrom,
                rangeTo: axis.rangeTo,
                data: axis.data,
                disabled: axis.disabled || false
            };
        });

        return axises;
    }

    drawCanvas() {
        this.ctx.save();
        this.ctx.fillStyle = "#fbfbfb";
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.restore();
        this.ctx.strokeStyle = '#999999';

        this.drawXAxis();
        this.drawYAxises();
        this.addData();
        this.connectData();
    }

    drawXAxis() {
        const distance = this.chartWidth / this.xSeparations;
        const commands = [];
        let dt = this.xAxis.rangeFrom;
        let lastDay = '';

        for (let i = 0; i <= this.xSeparations; i++) {
            commands.push({
                type: 'line',
                path: [
                    {x: this.leftOffset + Chart.halfWidth + distance * i, y: this.chartBottom},
                    {x: this.leftOffset + Chart.halfWidth + distance * i, y: this.chartBottom + 10}
                ]
            });

            let currentDay = moment(dt).format('DD MMM');

            if (lastDay !== currentDay) {
                lastDay = currentDay;

                commands.push({
                    type: 'text',
                    text: moment(dt).format('ddd'),
                    x: this.leftOffset + distance * i,
                    y: this.chartBottom + 25,
                    font: 'bold 13px Verdana'
                }, {
                    type: 'text',
                    text: currentDay,
                    x: this.leftOffset + distance * i,
                    y: this.chartBottom + 40,
                    font: 'bold 13px Verdana'
                });
            } else {
                commands.push({
                    type: 'text',
                    text: moment(dt).format('HH:mm'),
                    x: this.leftOffset + distance * i,
                    y: this.chartBottom + 25
                });
            }

            dt = moment(dt).add(this.xAxis.scale, 'm').toDate();
        }

        CanvasHelper.draw(this.ctx, {
            font: '13px Verdana',
            textAlign: 'center'
        }, commands);
    }

    drawYAxises() {
        // Draw left side
        this.yAxis.forEach((axis, idx) => {
            this.drawYAxis(idx);
        });

        const distance = this.chartHeight / this.ySeparations;
        const commands = [];

        // Draw Separators
        for (let i = 0; i <= this.ySeparations; i++) {
            commands.push({
                type: 'line',
                path: [
                    {x: this.leftOffset, y: Chart.topOffset + distance * i + Chart.halfWidth},
                    {x: this.chartRight, y: Chart.topOffset + distance * i + Chart.halfWidth}
                ]
            });
        }

        // Draw Right side
        commands.push({
            type: 'line',
            path: [
                {x: this.chartRight + Chart.halfWidth, y: Chart.topOffset},
                {x: this.chartRight + Chart.halfWidth, y: this.chartBottom}
            ]
        });

        CanvasHelper.draw(this.ctx, {}, commands);
    }

    drawYAxis(i) {
        const axis = this.yAxis[i];
        const leftOffset = Chart.axisOffset * (i + 1);
        const distance = this.chartHeight / this.ySeparations;
        const commands = [];

        commands.push({
            type: 'line',
            path: [
                {x: leftOffset + Chart.halfWidth, y: Chart.topOffset},
                {x: leftOffset + Chart.halfWidth, y: this.chartBottom}
            ]
        });

        let textLength = 0;
        const scale = (axis.rangeTo - axis.rangeFrom) / this.ySeparations;
        let hasFloats = false;

        for (let j = 0; j <= this.ySeparations; j++) {
            const num = axis.rangeTo - (j * scale);

            if (num % 1 !== 0) {
                hasFloats = true;
            }
        }

        for (let j = 0; j <= this.ySeparations; j++) {
            const num = axis.rangeTo - (j * scale);
            let text;

            if (hasFloats) {
                text = num.toFixed(1);
            } else {
                text = String(num);
            }

            textLength = Math.max(textLength, text.length);

            commands.push({
                type: 'line',
                path: [
                    {x: leftOffset + 1, y: Chart.topOffset + distance * j + Chart.halfWidth},
                    {x: leftOffset - 6, y: Chart.topOffset + distance * j + Chart.halfWidth}
                ]
            }, {
                type: 'text',
                text: text,
                x: leftOffset - 10,
                y: Chart.topOffset + distance * j + 5,
                align: 'right',
            })
        }

        commands.push({
            type: 'translate',
            x: leftOffset,
            y: distance * this.ySeparations / 2 + Chart.topOffset
        }, {
            type: 'rotate',
            angle: -Math.PI / 2
        }, {
            type: 'text',
            text: `${axis.name} (${axis.unit})`,
            align: 'center',
            font: '15px Verdana',
            x: 0,
            y: -25 + -textLength * 6
        });

        const color = CanvasHelper.hexToRgbA(axis.color, axis.disabled ? 0.5 : 1);

        CanvasHelper.draw(this.ctx, {
            strokeStyle: color,
            fillStyle: color,
            font: '13px Verdana'
        }, commands);
    }

    addData() {
        this.yAxis.forEach((axis: IAxis) => {
            if (axis.disabled) {
                return;
            }

            if (axis.type === 'line') {
                axis.data.forEach(this.addDataLine.bind(this, axis));
            } else if (axis.type === 'candle') {
                axis.data.forEach(this.addDataCandle.bind(this, axis));
            }
        });
    }

    connectData() {
        this.yAxis.forEach((axis: IAxis) => {
            if (axis.disabled) {
                return;
            }

            if (axis.type === 'line') {
                this.connectDataLine(axis);
            } else if (axis.type === 'candle') {
                this.connectDataCandle(axis);
            }
        });
    }

    addDataLine(axis, point) {
        const x = this.chartWidth * MathHelper.percentDateRange(this.xAxis.rangeFrom, this.xAxis.rangeTo, point.x);
        const y = this.chartHeight * MathHelper.percentRange(axis.rangeFrom, axis.rangeTo, point.y, true);

        CanvasHelper.draw(this.ctx, {
            fillStyle: axis.color,
            strokeStyle: axis.color
        }, [
            {
                type: 'arc',
                x: this.leftOffset + x,
                y: Chart.topOffset + y,
                radius: 4,
                fill: true
            }
        ]);
    }

    addDataCandle(axis, point) {
        const pointSideLength = 6;
        const x = this.chartWidth * MathHelper.percentDateRange(this.xAxis.rangeFrom, this.xAxis.rangeTo, point.x);
        const yTop = this.chartHeight * MathHelper.percentRange(axis.rangeFrom, axis.rangeTo, point.y[0], true);
        const yBottom = this.chartHeight * MathHelper.percentRange(axis.rangeFrom, axis.rangeTo, point.y[1], true);

        CanvasHelper.draw(this.ctx, {
            fillStyle: axis.color,
            strokeStyle: axis.color
        }, [
            {
                type: 'line',
                path: [
                    {x: this.leftOffset + x - pointSideLength, y: Chart.topOffset + yTop - pointSideLength},
                    {x: this.leftOffset + x, y: Chart.topOffset + yTop},
                    {x: this.leftOffset + x + pointSideLength, y: Chart.topOffset + yTop - pointSideLength}
                ],
                fill: true
            },
            {
                type: 'line',
                path: [
                    {x: this.leftOffset + x - pointSideLength, y: Chart.topOffset + yBottom + pointSideLength},
                    {x: this.leftOffset + x, y: Chart.topOffset + yBottom},
                    {x: this.leftOffset + x + pointSideLength, y: Chart.topOffset + yBottom + pointSideLength}
                ],
                fill: true
            }
        ]);
    }

    connectDataLine(axis) {
        const points = [];

        axis.data.forEach((point: IDataElement) => {
            const x = this.chartWidth * MathHelper.percentDateRange(this.xAxis.rangeFrom, this.xAxis.rangeTo, point.x);
            const y = this.chartHeight * MathHelper.percentRange(axis.rangeFrom, axis.rangeTo, point.y as number, true);

            points.push({x: x, y: y});
        });

        let prevPoint;

        points.forEach((point) => {
            if (!prevPoint) {
                prevPoint = point;
            } else {
                CanvasHelper.draw(this.ctx, {
                    fillStyle: axis.color,
                    strokeStyle: axis.color
                }, [
                    {
                        type: 'line',
                        path: [
                            {x: this.leftOffset + prevPoint.x, y: Chart.topOffset + prevPoint.y},
                            {x: this.leftOffset + point.x, y: Chart.topOffset + point.y}
                        ]
                    }
                ]);

                prevPoint = point;
            }
        });
    }

    connectDataCandle(axis) {
        axis.data.forEach((point: IDataElement) => {
            const x = this.chartWidth * MathHelper.percentDateRange(this.xAxis.rangeFrom, this.xAxis.rangeTo, point.x);
            const yTop = this.chartHeight * MathHelper.percentRange(axis.rangeFrom, axis.rangeTo, point.y[0], true);
            const yBottom = this.chartHeight * MathHelper.percentRange(axis.rangeFrom, axis.rangeTo, point.y[1], true);

            CanvasHelper.draw(this.ctx, {
                fillStyle: axis.color,
                strokeStyle: axis.color
            }, [
                {
                    type: 'line',
                    path: [
                        {x: this.leftOffset + x, y: Chart.topOffset + yTop},
                        {x: this.leftOffset + x, y: Chart.topOffset + yBottom}
                    ]
                }
            ]);
        });
    }
}

interface IDataElement {
    x: Date,
    y: any
}

interface IAxis {
    name?: string,
    unit?: string,
    type?: string,
    data?: IDataElement[],
    color?: string,
    scale: number,
    rangeFrom: any,
    rangeTo: any,
    disabled?: boolean
}
